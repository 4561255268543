
import {defineComponent} from 'vue';
import ProjectForm from '@/components/project/ProjectForm.vue';

export default defineComponent({
  name: 'ProjectDetailTabOverview',
  components: {
    ProjectForm,
  },
  setup() {
    return {};
  },
});
